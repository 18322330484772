<template>
  <ConfigPage>
    <ConfigItem>
      <v-card flat>
        <v-card-title>
          <v-btn
            :to="{ name: 'configs.leads.statuses' }"
            exact
            icon
            class="mr-4"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="handleClickNew()" color="primary"> Adicionar </v-btn>
        </v-card-title>

        <v-data-table :headers="headers" :items="items" :loading="loading">
          <template v-slot:item.status="{ item }">
            <a @click="handleClickUpdate(item)"> {{ item.status }} </a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="destroy(item)" color="red" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </ConfigItem>
    <InsurerStatusForm
      ref="store_form"
      title="Adicionar status"
      @submit="storeStatus"
    />
    <InsurerStatusForm
      ref="update_form"
      title="Atualizar status"
      @submit="updateStatus"
    />
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import {
  index,
  store,
  destroy,
  update,
} from "@/services/configs/leads/statuses/external_statuses";
import InsurerStatusForm from "@/components/LeadStatuses/Insurers/InsurerStatusForm.vue";
import ToastsMixin from "@/mixins/ToastsMixin";

export default {
  mixins: [ToastsMixin],

  components: {
    ConfigPage,
    ConfigItem,
    InsurerStatusForm,
  },

  props: {
    entity: String,
    title: String,
  },

  data() {
    return {
      headers: [
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
          align: "right",
        },
      ],
      items: [],
      loading: false,
    };
  },

  methods: {
    async index() {
      this.items = await index(this.entity);
    },

    getStoreForm() {
      return this.$refs.store_form;
    },

    getUpdateForm() {
      return this.$refs.update_form;
    },

    handleClickNew() {
      this.getStoreForm().open();
    },

    handleClickUpdate(status) {
      this.getUpdateForm().open({ ...status });
    },

    async storeStatus(form) {
      this.getStoreForm().loading = true;

      try {
        await store(this.entity, form);
        this.getStoreForm().close();
        this.index();
        this.toast("Salvo!");
      } catch (error) {
        console.error(error);

        if (error.response.status === 422) {
          this.getStoreForm().setErrors(error.response.data.errors);
          this.toastError("Corrija o formulário");
          return;
        }

        this.toastError("Erro inesperado");
      } finally {
        this.getStoreForm().loading = false;
      }
    },

    async updateStatus(form) {
      this.getUpdateForm().loading = true;

      try {
        await update(this.entity, form.id, { ...form });
        this.getUpdateForm().close();
        this.index();
        this.toast("Salvo!");
      } catch (error) {
        console.error(error);

        if (error.response.status === 422) {
          this.getUpdateForm().setErrors(error.response.data.errors);
          this.toastError("Corrija o formulário");
          return;
        }

        this.toastError("Erro inesperado");
      } finally {
        this.getUpdateForm().loading = false;
      }
    },

    async destroy(item) {
      if (!confirm("Tem certeza que deseja excluir o status?")) {
        return;
      }

      await destroy(this.entity, item.id);
      this.toast(`Status ${item.status} removido`);
      this.index();
    },
  },

  computed: {},

  created() {
    this.index();
  },
};
</script>
