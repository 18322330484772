<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="form.status"
            :error-messages="errors.status"
            outlined
            label="Nome"
            autofocus
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn type="submit" color="primary" :loading="loading">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  mixins: [],

  components: {},

  props: {
    title: String,
  },

  data() {
    return {
      dialog: false,
      form: {},
      errors: {},
      loading: false,
    };
  },

  methods: {
    open(form) {
      this.setForm(form);
      this.dialog = true;
    },

    setForm(form = null) {
      if (!form) {
        this.form = {
          status: "",
        };
        return;
      }

      this.form = form;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    close() {
      this.dialog = false;
    },

    submit() {
      this.setErrors();

      this.$emit("submit", this.form);
    },
  },

  computed: {},
};
</script>
